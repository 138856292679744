<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${$route.params.id}`"
    content-class="background-white">
    <!-- Header -->
    <section class="padding-x-16">
      <h1>Record New Activity</h1>
    </section>

    <!-- Activities for Mothers  -->
    <ion-grid v-if="user.role === 'mother'">
      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Team Note" icon="/assets/note.svg" @click="selectActivity('note')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Lactation Assistance" icon="/assets/lactation_assistance.svg"
            @click="selectActivity('lactation_assistance')"></activity-button>
        </ion-col>
        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Delivered Breast Pump" icon="/assets/breast_pump_deliver.svg"
            @click="selectActivity('pump_delivered')"></activity-button>
        </ion-col>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Retrieved Breast Pump" icon="/assets/breast_pump_retrieve.svg"
            @click="selectActivity('pump_retrieved')"></activity-button>
        </ion-col>

        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Sitz Bath" icon="/assets/sitz_bath.svg"
            @click="selectActivity('sitz_bath')"></activity-button>
        </ion-col>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Foot Bath" icon="/assets/foot_bath.svg"
            @click="selectActivity('foot_bath')"></activity-button>
        </ion-col>
        <!-- <ion-col size="4">
          <activity-button
            label="Breast Massage"
            icon="/assets/breast_massage.svg"
            @click="selectActivity('breast_massage')"
          ></activity-button>
        </ion-col> -->
        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>


      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Info Session (Zoom)" icon="/assets/sales_info_session.svg"
            @click="selectActivity('sales_info_session_zoom')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="1:1 CA Call" icon="/assets/sales_ca_call.svg"
            @click="selectActivity('sales_ca_call_zoom')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Tour (On-Site)" icon="/assets/sales_tour.svg"
            @click="selectActivity('sales_tour_onsite')"></activity-button>
        </ion-col>

        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Activities for Babies  -->
    <ion-grid v-if="user.role === 'baby'">
      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Team Note" icon="/assets/note.svg" @click="selectActivity('note')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Baby In Room" icon="/assets/baby_in_guest_room.svg"
            @click="selectActivity('baby_in_guest_room')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Baby In Nursery" icon="/assets/baby_in_nursery.svg"
            @click="selectActivity('baby_in_nursery')"></activity-button>
        </ion-col>
        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Collected Breastmilk" icon="/assets/breastmilk_received.svg"
            @click="selectActivity('breastmilk_received')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Bottle Prep" icon="/assets/baby_bottle_prep.svg"
            @click="selectActivity('baby_bottle_prep')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Feeding" icon="/assets/baby_bottle.svg"
            @click="selectActivity('baby_feeding')"></activity-button>
        </ion-col>

        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Diaper (Wet)" icon="/assets/baby_diaper_wet.svg"
            @click="selectActivity('baby_diaper_wet')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Diaper (Poop)" icon="/assets/baby_diaper_poop.svg"
            @click="selectActivity('baby_diaper_poop')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Diaper (Mixed)" icon="/assets/baby_diaper_mixed.svg"
            @click="selectActivity('baby_diaper_mixed')"></activity-button>
        </ion-col>

        <ion-col size="4" class="ion-text-center">
          <activity-button label="Diaper (Empty)" icon="/assets/baby_diaper_empty.svg"
            @click="selectActivity('baby_diaper_empty')"></activity-button>
        </ion-col>

        <ion-col size="12">
          <hr>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="4" class="ion-text-center">
          <activity-button label="Bath" icon="/assets/baby_bath.svg"
            @click="selectActivity('baby_bath')"></activity-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import ActivityButton from "@/components/users/activities/ActivityButton.vue";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    ActivityButton,
  },

  data() {
    return {
      user: {},
    };
  },

  ionViewWillEnter() {
    this.fetchUser();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectActivity(activityType) {
      if (activityType === "note") {
        this.$router.push(
          `/users/${this.$route.params.id}/activities/notes/create`
        );
      } else {
        this.$router.push(
          `/users/${this.$route.params.id}/activities/create?type=${activityType}`
        );
      }
    },
  },
};
</script>